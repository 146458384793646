<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="m-0 auth-inner">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="p-5 d-none d-lg-flex align-items-center">
        <div
          class="px-5 w-100 d-lg-flex align-items-center justify-content-center"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="px-2 d-flex align-items-center auth-bg p-lg-5">
        <b-col sm="8" md="6" lg="12" class="mx-auto px-xl-2">
          <b-card-text class="mb-2">
            Please sign-in to your account.
          </b-card-text>

          <b-alert variant="primary" show>
            <feather-icon
              v-b-tooltip.hover.left="'Having Trouble? Contact Us.'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert>
          <b-alert v-if="loginError" variant="warning" show>
            <div class="alert-body">
              {{ allertBody }}
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="mt-2 auth-login-form" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="username"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      autocomplete="current-password"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <div @click="setRememberMe()">
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <!-- submit buttons -->
              <b-button
                v-if="!signingIn"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
              <b-button v-else variant="primary" block :disabled="invalid">
                <b-spinner small type="grow"></b-spinner>
                Signing In ...
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="mt-2 text-center">
            <span>New to Public Digital? </span>
            <b-link href="https://publicdigital.net/register/">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="my-2 divider">
            <div class="divider-text">or</div>
          </div>

           social buttons 
          <div class="auth-footer-btn d-flex justify-content-center">
            <div
              id="customBtn"
              class="flex items-center justify-center w-full customGPlusSignIn"
              @click="loginWithGoogle()"
            >
              <img
                src="../../../assets/images/logo/512px-Google__G__Logo.png"
                class="block object-contain h-5 my-1 mr-1"
              />
              <span class="buttonText">Sign in with Google</span>
            </div>
          </div> -->
          <div class="text-xs text-gray-300">{{ version }}</div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import firebase from "firebase/app";
import "firebase/auth";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      signingIn: false,
      allertHead: "",
      allertBody: "",
      status: "",
      loginError: false,
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  beforeMount() {
    const rememberLogin = this.$CryptoCookie.getCookie("rememberLogin") || null;

    if (rememberLogin) {
      this.userEmail = rememberLogin.cookie;
      this.status = true;
    }
  },
  methods: {
    setRememberMe() {
      !this.status && this.userEmail
        ? this.$CryptoCookie.setCookie("rememberLogin", this.userEmail)
        : null;

      this.status ? this.$CryptoCookie.removeCookie("rememberLogin") : null;
    },
    loginWithGoogle() {
      const l1 = this;
      l1.signingIn = true;
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        // eslint-disable-next-line no-unused-vars
        .then((authUser) => {
         
          l1.$CryptoCookie.setCookie(
            "firstName",
            authUser.additionalUserInfo.profile.given_name
          );
          l1.$CryptoCookie.setCookie(
            "lastName",
            authUser.additionalUserInfo.profile.family_name
          );
        })
        .catch((error) => {
          l1.signingIn = false;
          l1.allertHead = error.code;
          l1.allertBody = error.message;
          l1.loginError = true;
        });
    },
    loginWithPassword() {
      const l1 = this;
      l1.signingIn = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(l1.userEmail, l1.password)
        // eslint-disable-next-line no-unused-vars
        .then((authUser) => {})
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          l1.signingIn = false;
          l1.allertHead = error.code;
          l1.allertBody = error.message;

          l1.loginError = true;
        });
    },
    login() {
      this.$refs.loginForm
        .validate()
        .then((success) => {
          if (success) {
            this.loginWithPassword();
          }
        })
        .catch((error) => {
          this.$refs.loginForm.setErrors(error.response.data.error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto");
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  border-radius: 2px;
  border: thin solid rgb(167, 165, 165);
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background-image: url("../../../assets/images/logo/512px-Google__G__Logo.png");
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}
</style>
